.horizontal-menu .content {
  margin-left: 0;
}

.horizontal-menu .content.show-overlay .content-overlay {
  z-index: 998 !important;
}

.horizontal-menu .navbar.header-navbar .navbar-container {
  padding: .8rem 2rem;
}

.horizontal-menu .horizontal-menu-wrapper .header-navbar {
  min-height: 4.45rem;
}

.horizontal-menu footer {
  position: static;
}

.horizontal-menu .horizontal-menu-wrapper {
  z-index: 990;
  width: 100%;
  position: fixed;
  top: 62px;
}

.horizontal-menu .horizontal-menu-wrapper .header-navbar .navbar-container {
  width: 100%;
  padding: 0 1rem;
}

.horizontal-menu .horizontal-menu-wrapper .header-navbar .navbar-header {
  display: none;
}

.horizontal-menu .header-navbar {
  background: #fff;
  min-height: auto;
  line-height: 1;
  z-index: 999 !important;
}

.horizontal-menu .header-navbar.navbar-light {
  background: #fff;
}

.horizontal-menu .header-navbar.navbar-horizontal.floating-nav {
  background: #fff;
  width: calc(100% - 4rem);
  top: 62px;
  left: 0;
}

.horizontal-menu .header-navbar .navbar-container {
  border-radius: .357rem;
}

.horizontal-menu .header-navbar.navbar-fixed {
  width: 100%;
  position: fixed;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
  z-index: 1000;
  padding: 0;
  position: absolute;
  left: calc(50% - 56px);
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand {
  font-size: inherit;
  align-items: center;
  margin-right: 0;
  display: flex;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-logo img {
  max-width: 36px;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-text {
  color: #7367f0;
  letter-spacing: .01rem;
  margin-bottom: 0;
  padding-left: 1rem;
  font-size: 1.45rem;
  font-weight: 600;
}

.horizontal-menu .header-navbar.navbar-horizontal .nav-link.dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  left: .4rem;
}

.horizontal-menu .header-navbar.navbar-horizontal .sidebar-group-active .dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu:before {
  display: none;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu {
  border: none;
  min-width: 215px;
  min-height: 52px;
  margin-top: 0;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .disabled {
  pointer-events: none !important;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .disabled a {
  color: #b8c2cc;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-toggle:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  margin-top: -7px;
  position: absolute;
  top: 50%;
  left: auto;
  right: 1rem;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-item {
  align-items: center;
  padding-top: .68rem;
  padding-bottom: .68rem;
  font-size: 1rem;
  display: flex;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu {
  position: relative;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu.openLeft .dropdown-menu {
  left: auto !important;
  right: 100% !important;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu.sidebar-group-active {
  background: #f8f8f8;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu > .dropdown-menu {
  position: absolute;
  top: 0 !important;
  left: 100% !important;
}

.horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu > .dropdown-menu i, .horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu .dropdown-submenu > .dropdown-menu svg {
  width: 11px !important;
  height: 11px !important;
  font-size: 11px !important;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li {
  padding-top: .857rem;
  padding-bottom: .857rem;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu a > * {
  transition: all .2s;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu a:hover {
  color: #6e6b7b;
  background-color: #0000;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu a:hover > * {
  transition: transform .2s;
  transform: translateX(5px);
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu .active > a {
  color: #7367f0;
  background: #f8f8f8;
  font-weight: 500;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu .open.active > a {
  color: #6e6b7b;
  font-weight: normal;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li i, .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li svg {
  width: 17px;
  height: 17px;
  margin-right: .5rem;
  font-size: 1.2rem;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > a {
  margin-right: .715rem;
  padding: .715rem 1.25rem;
  display: flex;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li:hover > a {
  background: #f8f8f8;
  border-radius: 4px;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li.active > a {
  color: #fff;
  background: linear-gradient(118deg, #7367f0, #7367f0b3);
  border-radius: 4px;
  box-shadow: 0 0 6px 1px #7367f099;
}

.horizontal-menu .navigation-header {
  color: #929292;
  text-transform: uppercase;
  padding: 8px 20px;
  font-family: inherit;
  font-size: 1rem;
}

.horizontal-menu .navbar-dark .nav-item.active > a {
  background-color: #313c50;
  border-bottom: 2px solid #7367f0;
}

.horizontal-layout.navbar-floating .header-navbar-shadow {
  height: 80px;
}

@media (width >= 1200px) {
  .horizontal-layout.navbar-floating .header-navbar-shadow {
    top: 45px;
  }
}

.horizontal-layout.navbar-floating:not(.blank-page) .app-content {
  padding: 12.2rem 2rem 0;
}

.horizontal-layout.navbar-floating .horizontal-menu-wrapper {
  background: linear-gradient(#f8f8f8f2 44%, #f8f8f875 73%, #fff0 100%) repeat-x;
}

.horizontal-layout.navbar-floating.footer-static .app-content .content-area-wrapper, .horizontal-layout.navbar-floating.footer-static .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 1)  + 4.45rem + 3.35rem + 1.3rem + 4.45rem));
  height: calc(100vh - 15.55rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-floating.footer-static .app-content .content-area-wrapper, .horizontal-layout.navbar-floating.footer-static .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 1)  + 4.45rem + 3.35rem));
    height: calc(100vh - 9rem);
  }
}

.horizontal-layout.navbar-floating.footer-hidden .app-content .content-area-wrapper, .horizontal-layout.navbar-floating.footer-hidden .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 0rem + 1.3rem + 4.45rem));
  height: calc(100vh - 14.2rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-floating.footer-hidden .app-content .content-area-wrapper, .horizontal-layout.navbar-floating.footer-hidden .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 2)  + 4.45rem + 0rem));
    height: calc(100vh - 6.85rem);
  }
}

.horizontal-layout.navbar-floating.footer-fixed .app-content .content-area-wrapper, .horizontal-layout.navbar-floating.footer-fixed .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 3.35rem + 1.3rem + 4.45rem));
  height: calc(100vh - 17.55rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-floating.footer-fixed .app-content .content-area-wrapper, .horizontal-layout.navbar-floating.footer-fixed .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 2)  + 4.45rem + 3.35rem));
    height: calc(100vh - 10.2rem);
  }
}

.horizontal-layout.navbar-sticky .app-content {
  padding: 10.9rem 2rem 0;
}

.horizontal-layout.navbar-sticky .header-navbar {
  box-shadow: none;
  background-color: #f8f8f8;
}

.horizontal-layout.navbar-sticky .horizontal-menu-wrapper .navbar-horizontal.header-navbar.fixed-top {
  background-color: #fff;
  top: 62px;
  left: 0;
  box-shadow: 0 4px 24px #22292f1a;
}

.horizontal-layout.navbar-sticky.footer-static .app-content .content-area-wrapper, .horizontal-layout.navbar-sticky.footer-static .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 1)  + 4.45rem + 3.35rem + 0rem + 4.45rem));
  height: calc(100vh - 14.25rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-sticky.footer-static .app-content .content-area-wrapper, .horizontal-layout.navbar-sticky.footer-static .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 1)  + 4.45rem + 3.35rem));
    height: calc(100vh - 9rem);
  }
}

.horizontal-layout.navbar-sticky.footer-hidden .app-content .content-area-wrapper, .horizontal-layout.navbar-sticky.footer-hidden .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 0rem + 0rem + 4.45rem));
  height: calc(100vh - 12.9rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-sticky.footer-hidden .app-content .content-area-wrapper, .horizontal-layout.navbar-sticky.footer-hidden .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 2)  + 4.45rem + 0rem));
    height: calc(100vh - 6.85rem);
  }
}

.horizontal-layout.navbar-sticky.footer-fixed .app-content .content-area-wrapper, .horizontal-layout.navbar-sticky.footer-fixed .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 3.35rem + 0rem + 4.45rem));
  height: calc(100vh - 16.25rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-sticky.footer-fixed .app-content .content-area-wrapper, .horizontal-layout.navbar-sticky.footer-fixed .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 2)  + 4.45rem + 3.35rem));
    height: calc(100vh - 10.2rem);
  }
}

.horizontal-layout.navbar-static .app-content {
  padding: 6.45rem 2rem 0;
}

.horizontal-layout.navbar-static .content {
  min-height: calc(100% - 8rem);
}

.horizontal-layout.navbar-static .header-navbar {
  box-shadow: none;
  background-color: #f8f8f8;
}

.horizontal-layout.navbar-static .horizontal-menu-wrapper {
  position: relative;
}

.horizontal-layout.navbar-static .horizontal-menu-wrapper .navbar-horizontal.header-navbar {
  background: #fff;
}

.horizontal-layout.navbar-static .horizontal-menu-wrapper .navbar-horizontal.header-navbar.navbar-static-top {
  width: 100%;
  left: 0;
}

.horizontal-layout.navbar-static.footer-static .app-content .content-area-wrapper, .horizontal-layout.navbar-static.footer-static .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 1)  + 4.45rem + calc(3.35rem + .2rem)  + 0rem + 4.45rem));
  height: calc(100vh - 14.45rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-static.footer-static .app-content .content-area-wrapper, .horizontal-layout.navbar-static.footer-static .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 1)  + 4.45rem + calc(3.35rem + .2rem)));
    height: calc(100vh - 9.2rem);
  }
}

.horizontal-layout.navbar-static.footer-hidden .app-content .content-area-wrapper, .horizontal-layout.navbar-static.footer-hidden .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 0rem + 0rem + 4.45rem));
  height: calc(100vh - 12.9rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-static.footer-hidden .app-content .content-area-wrapper, .horizontal-layout.navbar-static.footer-hidden .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 2)  + 4.45rem + 0rem));
    height: calc(100vh - 6.85rem);
  }
}

.horizontal-layout.navbar-static.footer-fixed .app-content .content-area-wrapper, .horizontal-layout.navbar-static.footer-fixed .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 4.45rem + 3.35rem + 0rem + 4.45rem));
  height: calc(100vh - 16.25rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-static.footer-fixed .app-content .content-area-wrapper, .horizontal-layout.navbar-static.footer-fixed .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 2)  + 4.45rem + 3.35rem));
    height: calc(100vh - 10.2rem);
  }
}

.horizontal-layout.navbar-hidden.footer-static .app-content .content-area-wrapper, .horizontal-layout.navbar-hidden.footer-static .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 1)  + 0rem + 3.35rem + 0rem + 4.45rem));
  height: calc(100vh - 9.8rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-hidden.footer-static .app-content .content-area-wrapper, .horizontal-layout.navbar-hidden.footer-static .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 1)  + 0rem + 3.35rem));
    height: calc(100vh - 4.55rem);
  }
}

.horizontal-layout.navbar-hidden.footer-hidden .app-content .content-area-wrapper, .horizontal-layout.navbar-hidden.footer-hidden .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 0rem + 0rem + 0rem + 4.45rem));
  height: calc(100vh - 8.45rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-hidden.footer-hidden .app-content .content-area-wrapper, .horizontal-layout.navbar-hidden.footer-hidden .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 2)  + 0rem + 0rem));
    height: calc(100vh - 2.4rem);
  }
}

.horizontal-layout.navbar-hidden.footer-fixed .app-content .content-area-wrapper, .horizontal-layout.navbar-hidden.footer-fixed .app-content .kanban-wrapper {
  height: calc(var(--vh, 1vh) * 100 - calc(calc(2rem * 2)  + 0rem + 3.35rem + 0rem + 4.45rem));
  height: calc(100vh - 11.8rem);
}

@media (width <= 1199.98px) {
  .horizontal-layout.navbar-hidden.footer-fixed .app-content .content-area-wrapper, .horizontal-layout.navbar-hidden.footer-fixed .app-content .kanban-wrapper {
    height: calc(var(--vh, 1vh) * 100 - calc(calc(calc(2rem - .8rem) * 2)  + 0rem + 3.35rem));
    height: calc(100vh - 5.75rem);
  }
}

.horizontal-layout.vertical-overlay-menu #main-menu-navigation > li > ul > li > a i, .horizontal-layout.vertical-overlay-menu #main-menu-navigation > li > ul > li > a svg {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

@media (width <= 1199.98px) {
  .horizontal-layout.horizontal-menu .horizontal-menu-wrapper .header-navbar {
    display: none;
  }

  .horizontal-layout .header-navbar {
    background: #fff;
  }

  .horizontal-layout .app-content {
    padding: 5.65rem 1.2rem 0 !important;
  }
}

@media (width <= 575.98px) {
  html body.horizontal-layout.navbar-static .app-content {
    padding: 5.65rem 1.2rem 0 !important;
  }
}

.vertical-overlay-menu .content {
  margin-left: 0;
}

.vertical-overlay-menu .navbar .navbar-header {
  float: left;
  width: 260px;
}

.vertical-overlay-menu .main-menu, .vertical-overlay-menu.menu-hide .main-menu {
  opacity: 0;
  width: 260px;
  transition: width .25s, opacity .25s, transform .25s;
  left: -260px;
  transform: translate3d(0, 0, 0);
}

.vertical-overlay-menu .main-menu .navigation > li > a > svg, .vertical-overlay-menu .main-menu .navigation > li > a > i {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 14px;
  transition: all .2s;
}

.vertical-overlay-menu .main-menu .navigation > li > a > svg:before, .vertical-overlay-menu .main-menu .navigation > li > a > i:before {
  font-size: 1.429rem;
  transition: all .2s;
}

.vertical-overlay-menu .main-menu .navigation li.has-sub > a:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1rem;
  height: 1rem;
  transition: all .2s ease-out;
  display: inline-block;
  position: absolute;
  top: 14px;
  right: 20px;
  transform: rotate(0);
}

.vertical-overlay-menu .main-menu .navigation li.has-sub.open:not(.menu-item-closing) > a:after {
  transform: rotate(90deg);
}

.vertical-overlay-menu .main-menu .navigation .navigation-header .feather-more-horizontal {
  display: none;
}

.vertical-overlay-menu.menu-open .main-menu {
  opacity: 1;
  transition: width .25s, opacity .25s, transform .25s;
  transform: translate3d(260px, 0, 0);
}
/*# sourceMappingURL=index.4c6bd3b5.css.map */
